import './App.css';
import {useEffect, useState} from 'react';

import desktopTopPng from './assets/desktop-top-1350.png';
import desktopCenterPng from './assets/desktop-center-1350.png';
import desktopBottomPng from './assets/desktop-bottom-1350.png';

import mobilePng from './assets/mobile-990.png';

import boomLogo from './assets/boom-logo-512.png';
import hkmLogo from './assets/hkm-logo-512.png';
import mobileFooterCenterPng from './assets/mobile-footer-center.png';

import badgeApple from './assets/apple-appstore-badge-white.svg';
import badgeGoogle from './assets/google-play-badge-white.png';
import badgeHuawei from './assets/huawei-appgallery-badge-white.png';

function getMobileState() {
  return window.innerWidth < 768;
}
function App() {
  // Определение ширины экрана для выбора нужного изображения
  const [isMobile, setMobile] = useState(getMobileState());
  useEffect(() => {
    const onResize = () => {
      setMobile(getMobileState());
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [setMobile]);

  // Определение мобильного устройства
  let badgeName;
  if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|ipod)/)) {
    // iPhone Version:
    badgeName = 'apple';
  } else if (navigator.userAgent.match(/android/i)) {
    // Android Version:
    badgeName = 'google';
  } else if (navigator.userAgent.match(/huawei/i)) {
    // Huawei:
    badgeName = 'huawei';
  }

  return (
    <div className="App">
      {isMobile ? (
        <>
          <img className="Pict" src={mobilePng} alt="Main page" />
          <div className="Footer">
            <a className="AppIconLink" href={badgeName ? appLinks[badgeName].hkm : 'https://hunkemoller.by'}>
              <img className="AppIcon" src={hkmLogo} alt="Hunkemoller" />
            </a>
            <img className="Badge" src={badgeName ? appLinks[badgeName].badge : mobileFooterCenterPng} alt="Badge" />
            <a className="AppIconLink" href={badgeName ? appLinks[badgeName].boom : 'https://boomkids.by'}>
              <img className="AppIcon" src={boomLogo} alt="Boomkids" />
            </a>
          </div>
        </>
      ) : (
        <>
          <img className="Pict" src={desktopTopPng} alt="Main page top" />
          <div className="DesktopCenter">
            <img className="Pict" src={desktopCenterPng} alt="Main page center" />
            <a
              className="DesktopLink"
              href={badgeName ? appLinks[badgeName].hkm : 'https://hunkemoller.by'}
              style={{left: 0}}>
              Hunkemoller
            </a>
            <a
              className="DesktopLink"
              href={badgeName ? appLinks[badgeName].boom : 'https://boomkids.by'}
              style={{right: 0}}>
              Boomkids
            </a>
          </div>
          <img className="Pict" src={desktopBottomPng} alt="Main page bottom" />
        </>
      )}
    </div>
  );
}

const appLinks = {
  apple: {
    boom: 'https://apps.apple.com/us/app/boomkids/id1601222294',
    hkm: 'https://apps.apple.com/us/app/hunkemoller-belarus/id1601961019',
    badge: badgeApple,
  },
  google: {
    boom: 'https://play.google.com/store/apps/details?id=by.boomkids',
    hkm: 'https://play.google.com/store/apps/details?id=by.hunkemoller',
    badge: badgeGoogle,
  },
  huawei: {
    boom: 'https://appgallery.huawei.com/app/C105183275',
    hkm: 'https://appgallery.huawei.com/app/C105344173',
    badge: badgeHuawei,
  },
};

export default App;
